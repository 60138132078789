import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

// Retrieve data for flags
let savedPlayerName = window.localStorage.getItem("playerName");
let language =
    (typeof navigator.language === "string"
        && navigator.language.indexOf("fr") === 0
    )
        ? "fr" : "en";

// Initiate WebSocket connection
let socketWasOpen = false;

let socketClose = event => {
    console.info(event);

    if (!socketWasOpen) {
        window.alert(language === "fr" ? "Impossible de se connecter au serveur." : "Unable to connect to the server.");
    } else {
        window.alert(language === "fr" ? "La connexion a été perdue." : "Connection has been lost.");
    }
}

let socketError = event => {
    if (socketWasOpen) {
        console.error("WebSocket error", event);
        window.alert(language === "fr" ? "Une erreur est survenue." : "An error has occurred");
    }
}

const socket = new WebSocket(process.env.ELM_APP_DIXIT_SERVER);

socket.addEventListener("open", event => { socketWasOpen = true; });
socket.addEventListener("error", socketError);
socket.addEventListener("close", socketClose);


// Initiate Elm app
const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    savedPlayerName: (savedPlayerName ? savedPlayerName : ""),
    language: language,
  }
});


// Ports

app.ports.alert.subscribe(message => {
    window.alert(message);
})

app.ports.sendToSocket.subscribe(message => {
    let doSend = message => {
        socket.send(message + "\r\n");
    };

    if (socket.readyState != WebSocket.OPEN) {
        socket.addEventListener("open", event => { doSend(message); });
    } else {
        doSend(message);
    }
});

socket.addEventListener("message", event => {
    app.ports.receiveFromSocket.send(event.data);
})


app.ports.savePlayerName.subscribe(message => {
    window.localStorage.setItem("playerName", message);
});

app.ports.saveGameName.subscribe(message => {
    window.localStorage.setItem("gameName", message);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
